import { CanvasController } from "./CanvasController";

export interface MainController {
  onClientData: (a: Float32Array) => void
  onZoneUpdate: (a: Float32Array) => void
  onPerformanceStageUpdate: (n: number) => void
  onConnectionEstablished: (id: number) => void
  onConnectionLost: (id: number) => void
  showError: (e:Error | any) => void
  clientId: number
  setCanvasController: (c: CanvasController) => void
}

export interface Hotspot {
  position: Vector
  activation: number
  lastActivation: number
  mouseOver: boolean
  
  available: boolean
  wasAvailable: boolean
}

export interface Vector {
  x: number
  y: number
}

export interface Rect {
  x: number,
  y: number,
  width: number,
  height: number
}

export interface RGBA {
  r: number,
  g: number,
  b: number,
  a: number
}

export interface Client {
  target: Vector
  position: Vector
  mouseDown: boolean
  velocity: Vector
  previousPositions: Vector[]
  previousClicks: boolean[],
  initial: string
}

export interface ZoneTransition {
  from: Float32Array,
  to: Float32Array
}

export enum PerformanceStages {
  NO_PERFORMANCE = 1,
  PRE_PERFORMANCE = 2,
  PERFORMANCE_STARTED_WITH_ANNOTATIONS = 3,
  PERFORMANCE_STARTED = 4,
  PERFORMANCE_ENDED = 5
}

export type ClientMap = Map<number, Client>