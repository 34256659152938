import { Vector, ClientMap, Hotspot, Client, MainController, RGBA, PerformanceStages } from './types'
import { VUtil, valueTween, colourTween, rgbaToString, vectorInRect, getHotspotOpacity } from './Util'


interface MoveEvent {
  clientX: number,
  clientY: number
}

export class CanvasController {

  canvas: HTMLCanvasElement 
  ctx: CanvasRenderingContext2D
  owner: MainController
  error: Error | null = null
  silhouetteSVG: SVGElement
  silhouette: HTMLImageElement | null = null

  running: boolean = true

  mouseDown: Boolean = false
  position: Vector = { x: 0.5, y: 0.5 }
  mouseInBounds: Boolean = false

  clientMap: ClientMap = new Map()
  lastTick: number
  activationValues: Float32Array = new Float32Array([0, 0, 0, 0])

  availableZones: Float32Array = new Float32Array([0, 0, 0, 0])
  lastAvailableZones: Float32Array = new Float32Array([0, 0, 0, 0])

  performanceStage: number = PerformanceStages.PRE_PERFORMANCE
  lastPerformanceStage: number = PerformanceStages.PRE_PERFORMANCE

  tweenLength: number = 150;
  padding: number = 10

  hotspotColour: RGBA = { r: 50, g: 197, b: 255, a: 1 }
  neutralColour: RGBA = { r: 255, g: 255, b: 255, a: 1 }

  hotspots: Hotspot[] = [
    { position: { x: 0, y: 0 }, activation: 0, lastActivation: 0, mouseOver: false, available: false, wasAvailable: false },
    { position: { x: 0.5, y: 0 }, activation: 0, lastActivation: 0, mouseOver: false, available: false, wasAvailable: false },
    { position: { x: 0, y: 0.5 }, activation: 0, lastActivation: 0, mouseOver: false, available: false, wasAvailable: false },
    { position: { x: 0.5, y: 0.5 }, activation: 0, lastActivation: 0, mouseOver: false, available: false, wasAvailable: false }
  ]

  constructor (canvas: HTMLCanvasElement, owner: MainController) {
    this.canvas = canvas
    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    this.ctx.font = 'bold 48px objektiv-mk1'
    this.lastTick = new Date().getTime()
    this.owner = owner
    this.setupEventListeners()

    let l: Element = document.getElementById('silhouette')!
    this.silhouetteSVG = l as SVGElement
    let svgURL = new XMLSerializer().serializeToString(this.silhouetteSVG);
    this.silhouette = new Image()
    this.silhouette.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
  }

  setupEventListeners () {
    const { onResize, onMouseDown, onMouseUp, onMouseMove, onTouchStart, onTouchMove } = this

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchmove', onTouchMove)
    document.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('touchstart', onTouchStart)
    document.addEventListener('touchend', onMouseUp)
    document.addEventListener('touchcancel', onMouseUp)
    
    window.addEventListener('resize', onResize)

    this.onResize();
    requestAnimationFrame(this.draw)
  }

  update (clientMap: ClientMap, activationValues: Float32Array, availableZones: Float32Array, performanceStage: number, time: number) {
    this.clientMap = clientMap
    this.activationValues = activationValues
    
    this.lastAvailableZones = this.availableZones
    this.availableZones = availableZones
    
    this.lastPerformanceStage = this.performanceStage
    this.performanceStage = performanceStage

    this.lastTick = time

    this.hotspots.forEach((hotspot, i) => {
      hotspot.lastActivation = hotspot.activation
      hotspot.activation = activationValues[i]

      hotspot.wasAvailable = hotspot.available
      hotspot.available = Boolean(availableZones[i])
    })
  }


  getMousePosition (): Vector {
    return this.position
  }

  onResize = () => {
    const surfaceRect = this.canvas.getBoundingClientRect()
    const { width, height } = surfaceRect
    this.canvas.width = width
    this.canvas.height = height
  }

  onMouseDown = (e: MoveEvent) => {
    this.mouseDown = true 
  }

  onMouseUp = () => {
    this.mouseDown = false
  }

  onMouseMove = (e: MoveEvent) => {
    const surfaceRect = this.canvas.getBoundingClientRect()

    const { width, height } = surfaceRect
    const left = surfaceRect.x
    const top = surfaceRect.y
    const { clientX, clientY } = e

    let x = clientX - left
    let y = clientY - top

    if (x > 0 && x < width && y > 0 && y < height) {
      this.mouseInBounds = true
    }
    else {
      this.mouseInBounds = false
    }

    if (x < 0) x = 0
    if (y < 0) y = 0
    if (x > width) x = width
    if (y > height) y = height

    x = x / width
    y = y / height

    if (isNaN(x)) x = 0.5
    if (isNaN(y)) y = 0.5

    this.position = { x, y }

    this.hotspots.forEach((hotspot) => {
      const { position: p } = hotspot
      if (x > p.x && x < p.x + 0.5 && y > p.y && y < p.y + 0.5) {
        hotspot.mouseOver = true
      }
    })

    
  }

  onTouchStart = (e: TouchEvent) => {
    this.onMouseDown(e.touches[0])
    this.onTouchMove(e)
  }

  onTouchMove = (e: TouchEvent) => {
    this.onMouseMove(e.touches[0])
  }

  drawSilhouette () {
    const ratio = 0.6363
    const rect = this.canvas.getBoundingClientRect()

    const height = rect.height * .8
    const width = height * ratio
    
    const x = (rect.width - width) / 2
    const y = (rect.height - height) / 2

    this.ctx.drawImage(this.silhouette!, x, y, width, height)
  }

  drawProgressBars (dt: number, width: number, height: number) {
    const { ctx, hotspots, padding } = this
    
    ctx.strokeStyle = 'rgba(0, 0, 0, 0)'
    ctx.lineWidth = 0
    ctx.fillStyle = '#32C5FF'

    hotspots.forEach((hotspot, i) => {
      const { position, activation, lastActivation, available, wasAvailable } = hotspot
      let newWidth = valueTween(lastActivation, activation, dt)

      let halfWidth = (width - padding * 3) / 2
      let x = position.x * width + padding
      let y = position.y * height + padding

      if (i % 2 === 1) {
        x -= padding / 2
      }
      if (i > 1) {
        y -= padding / 2
      }

      const currentOpacity = available ? 1 : 0
      const priorOpacity = wasAvailable ? 1 : 0
      const opacity = valueTween(priorOpacity, currentOpacity, dt) 

      ctx.fillStyle = `rgba(255, 255, 255, ${opacity * 0.15})`
      ctx.fillRect(x, y, halfWidth, this.padding)

      ctx.fillStyle = rgbaToString({...this.hotspotColour, a: opacity})
      ctx.fillRect(x, y, newWidth * halfWidth, this.padding)
    })
  }

  drawHotspots (dt: number, width: number, height: number) {
    
    // Get 'us'
    const user = this.clientMap.get(this.owner.clientId)
    if (!user) return
    
    const { ctx, padding } = this
    let halfWidth = (width - padding * 3) / 2
    let halfHeight = (height - padding * 3) / 2

    this.hotspots.forEach((hotspot, i) => {
      
      const { position, activation, lastActivation, wasAvailable, available } = hotspot
      const { previousClicks, mouseDown, previousPositions, target } = user
      const lastClick = previousClicks[0] || false
      const lastPosition = previousPositions[0] || false

      const posRect = {...position, width: 0.5, height: 0.5 }
      const wasThere = lastClick && vectorInRect(lastPosition, posRect)
      const isThere = mouseDown && vectorInRect(target, posRect)
      
      let fromOpacity = getHotspotOpacity(wasThere, wasAvailable, lastActivation === 1)
      let toOpacity = getHotspotOpacity(isThere, available, activation === 1)

      // Goddamn it
      const fromColour = lastActivation === 1 ? this.hotspotColour : this.neutralColour
      const targetColour = activation === 1 ? this.hotspotColour : this.neutralColour

      const renderColour = colourTween({...fromColour, a: fromOpacity}, {...targetColour, a: toOpacity}, dt)
      ctx.fillStyle = rgbaToString(renderColour)

      let barHeight = padding + 1

      // Work out where to draw it
      let x = position.x * width + padding
      let y = position.y * height + padding + barHeight

      if (i % 2 === 1) {
        x -= padding / 2
      }
      if (i > 1) {
        y -= padding / 2
      }


      // ctx.fillStyle = fillStyle
      ctx.fillRect(x, y, halfWidth, halfHeight - barHeight)

    })
  
  }

  updateClientPosition (c: Client) {
    
    let averagePosition = {x: c.target.x, y: c.target.y}
    // if (c.previous.length > 0) {
    //   averagePosition.x = 0
    //   averagePosition.y = 0
    //   let totals = 0
    //   c.previous.forEach((v, i) => {
    //     averagePosition.x += v.x * (c.previous.length - i)
    //     averagePosition.y += v.y * (c.previous.length - i)
    //     totals += (c.previous.length - i)
    //   })
    //   averagePosition.x /= totals
    //   averagePosition.y /= totals
    // }
    
    // Not tweening directly here
    let deltaToTarget = VUtil.subtract(averagePosition, c.position)
    deltaToTarget = VUtil.scale(deltaToTarget, 0.1)
    c.position = VUtil.add(c.position, deltaToTarget)
  }

  updateOwnClientPosition (c: Client) {
    let deltaToTarget = VUtil.subtract(this.position, c.position)
    deltaToTarget = VUtil.scale(deltaToTarget, 0.2)
    c.position = VUtil.add(c.position, deltaToTarget)
  }

  drawClient (c: Client, width: number, height: number, isUser: boolean, dt: number, isOwner: boolean = false) {
    const { ctx } = this
    const { position, mouseDown, previousClicks } = c

    const x = position.x * width
    const y = position.y * height

    const lastClick = previousClicks[0]
    
    const fromOpacity = lastClick ? 1 : 0
    const toOpacity = mouseDown ? 1 : 0

    const opacity = valueTween(fromOpacity, toOpacity, dt)
    
    ctx.beginPath()
    ctx.arc(x, y, 8, 0, Math.PI * 2)
    ctx.strokeStyle= `rgba(255, 255, 255, ${opacity})`
    ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`
    ctx.shadowColor = `rgba(0,0,0,0)`
    ctx.closePath()
    
    ctx.fill()

    const letterToDraw = c.initial
    // const letterToDraw = alphabet[new Date().getSeconds() % 26]

    ctx.strokeStyle = `rgba(0, 0, 0, ${opacity})`
    ctx.strokeText(letterToDraw, x, y + 3.8)
  }

  drawPerformanceStartMessage (dt: number, width: number, height: number) {
        
    const fromOpacity = this.lastPerformanceStage === 2 ? 1 : 0
    const toOpacity = this.performanceStage === 2 ? 1 : 0
    const opacity = valueTween(fromOpacity, toOpacity, dt)

    const fontSize = width > 400 ? '24px' : '18px'

    this.ctx.font = `normal ${fontSize} objektiv-mk1`
    this.ctx.textAlign = 'center'
    this.ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`//r: 50, g: 197, b: 255
    this.ctx.fillText('The performance of STRINGS', width / 2, height / 2 - 18 - 20)
    this.ctx.fillText('will start shortly', width / 2, height / 2 + 18 - 20)
  }

  drawAnnotations = (dt: number, width: number, height: number) => {
    
    const { clientMap, padding } = this
    const totalConnected = clientMap.size
    let totalClicking = 0
    
    clientMap.forEach((c: Client) => {
      if (c.mouseDown) totalClicking++ 
    });

    const minRequiredClicks = 0.1
    let threshold: number
    let sufficientClicks = clientMap.size * minRequiredClicks

    if (totalClicking <= sufficientClicks) {
      threshold = Math.ceil(sufficientClicks)
    }
    else {
      threshold = Math.ceil(totalClicking * 0.51)
    }

    const annotatedHotspots = [this.hotspots[1], this.hotspots[2]]

    annotatedHotspots.forEach((hotspot, i) => {
      const { position: p, available, wasAvailable } = hotspot
      let fromOpacity: number, toOpacity: number

      let opacity = 0

      if (this.performanceStage !== PerformanceStages.PERFORMANCE_STARTED_WITH_ANNOTATIONS) {
        opacity = 0
      }
      else {
        if (available) opacity = 1
      }
      // const opacity = valueTween(fromOpacity, toOpacity, dt)

      let numberInCell = 0
      
      clientMap.forEach((c: Client) => {
        if (c.mouseDown && c.position.x > p.x && c.position.x < p.x + 0.5 && c.position.y > p.y && c.position.y < p.y + 0.5) {
          numberInCell++
        }
      })

      const prevTextAlign = this.ctx.textAlign
      const prevFontStyle = this.ctx.font

      let textAlign: CanvasTextAlign
      let textPos: Vector
      
      if (i === 0) {
        textAlign = 'end'
        textPos = {x: hotspot.position.x * width - padding, y: hotspot.position.y * height + padding * 2}
      }
      else {
        textAlign = 'left'
        textPos = {x: hotspot.position.x * width + width * .5 + padding, y: hotspot.position.y * height + padding * 2}
      }

      let message

      if (numberInCell >= threshold) message = 'Activated'
      else { 
        message = `${threshold - numberInCell} more persons needed`
      }
      
      this.ctx.fillStyle = rgbaToString({...this.hotspotColour, a: opacity})
      this.ctx.textAlign = textAlign
      this.ctx.font = `normal 10px objektiv-mk1`
      this.ctx.fillText(message, textPos.x, textPos.y)
        
      this.ctx.textAlign = prevTextAlign
      this.ctx.font = prevFontStyle
    });

  } 

  draw = () => {

    try {
      
      const rect = this.canvas.getBoundingClientRect()
      const { width, height } = rect
      const { ctx, clientMap } = this

      ctx.clearRect(0, 0, width, height)
      ctx.strokeStyle = ''
      ctx.lineWidth = 2
      ctx.fillStyle = '#000'

      // Black background
      ctx.fillRect(0, 0, width, height)
      
      let dt = new Date().getTime() - this.lastTick
      if (dt > this.tweenLength) dt = this.tweenLength

      this.drawSilhouette()
      this.drawHotspots(dt, width, height)
      this.drawProgressBars(dt, width, height)

      this.ctx.font = `normal 10px objektiv-mk1`
      
      clientMap.forEach((client, id) => {
        if (id === this.owner.clientId) this.updateOwnClientPosition(client)
        else this.updateClientPosition(client)
        this.drawClient(client, width, height, id === this.owner.clientId, dt, id === this.owner.clientId)
      })

      this.drawPerformanceStartMessage(dt, width, height)
      this.drawAnnotations(dt, width, height)
    
    }

    catch (e) {
      console.log(e)
    }

    if (this.running) requestAnimationFrame(this.draw)

  }

}