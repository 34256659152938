import React, { Component } from 'react'
import { alphabet } from './Util'


import YOU from "./images/2_you.png"
import QUADRANTS from "./images/3_quadrants.png"
import TOUCHING from "./images/4_keep-touching.png"
import COLLABORATE from "./images/5_collaborate.png"

interface Props {
  onLetterSet: (letter: number) => void,
  onFinished: () => void,
  className: string
}

interface State {
  page: number,
  selectedLetter: string | null
}

export class Instructions extends Component<Props, State> {

  constructor (props: Props) {
    super(props)
    this.state = {
      page: 0,
      selectedLetter: null
    }
  }

  getPage () {
    const { page, selectedLetter } = this.state

    switch (page) {
      case 0: 
        return (
          <div>
            <div className="title">Select the first letter of your name</div>
            <div className="letter-selector">
            <div className="letter-row">
            { ['A', 'B', 'C', 'D', 'E'].map((l) => (
              <div 
                className={'letter ' + ((l === selectedLetter) ? 'selected' : '')} 
                key={l} 
                onClick={() => this.setState({selectedLetter: l})}>
                  <div className="inner">{l}</div>
                </div>
            )) }
            </div>
            <div className="letter-row">
            { ['F', 'G', 'H', 'I', 'J'].map((l) => (
              <div 
                className={'letter ' + ((l === selectedLetter) ? 'selected' : '')} 
                key={l} 
                onClick={() => this.setState({selectedLetter: l})}>
                  <div className="inner">{l}</div>
                </div>
            )) }
            </div>
            <div className="letter-row">
            { ['K', 'L', 'M', 'N', 'O'].map((l) => (
              <div 
                className={'letter ' + ((l === selectedLetter) ? 'selected' : '')} 
                key={l} 
                onClick={() => this.setState({selectedLetter: l})}>
                  <div className="inner">{l}</div>
                </div>
            )) }
            </div>
            <div className="letter-row">
            { ['P', 'Q', 'R', 'S', 'T'].map((l) => (
              <div 
                className={'letter ' + ((l === selectedLetter) ? 'selected' : '')} 
                key={l} 
                onClick={() => this.setState({selectedLetter: l})}>
                  <div className="inner">{l}</div>
                </div>
            )) }
            </div>
            <div className="letter-row">
            { ['U', 'V', 'W', 'X', 'Y'].map((l) => (
              <div 
                className={'letter ' + ((l === selectedLetter) ? 'selected' : '')} 
                key={l} 
                onClick={() => this.setState({selectedLetter: l})}>
                  <div className="inner">{l}</div>
                </div>
            )) }
            </div>
            <div className="letter-row">
            { ['Z'].map((l) => (
              <div
                className={'letter ' + ((l === selectedLetter) ? 'selected' : '')} 
                key={l} 
                onClick={() => this.setState({selectedLetter: l})}>
                  <div className="inner">{l}</div>
                </div>
            )) }
            </div>
          </div>
        </div>
        )

      case 1:
        return (
          <div className="you-container">
            <div className="you"><div className="actual-letter">{ selectedLetter }</div></div>
            <img src={YOU} />
            <div className="title">This is how you will be represented on the screen during the performance.</div>
          </div>
        )

      case 2: 
          return (
            <div>
              <img src={QUADRANTS} alt=""/>
              <div className="title">Click on a quadrant to contribute and activate a body part. Only one zone is activated at a time.</div>
            </div>
          )
      case 3: 
          return (
            <div>
              <img src={TOUCHING} alt=""/>
              <div className="title">Keep touching for the body part to stay activated. Release to deactivate.</div>
            </div>
          )

      default:
        return (
          <div>
            <img src={COLLABORATE} alt=""/>
            <div className="title">You’ll need to agree with the other participants. At least half are needed in the same zone to activate it.</div>
          </div>
        )
    }
  }

  getPageIndicator () {
    return (
      <div className="page-indicator">
        {
          [0, 1, 2, 3, 4].map((i) => {
            return (
              <div
                className={ 'marker ' + (i === this.state.page ? 'highlighted' : '') }
              >

              </div>
            )
          })
        }
      </div>
    )
  }

  next = () => {
    const { page, selectedLetter } = this.state
    const { onLetterSet, onFinished } = this.props
    if (page === 0 && selectedLetter !== null) {
      onLetterSet(alphabet.indexOf(selectedLetter))
    }
    if (page === 4) {
      onFinished()
    }
    else {
      this.setState({ page: this.state.page + 1 })
    }

  }

  back = () => {
    this.setState({ page: this.state.page - 1 })
  }


  render () {
    
    const { className } = this.props
    const { page, selectedLetter } = this.state
    
    return (
      <div className={className}>
        <div className="instructions-inner">
          { this.getPage() }
        

          <div className="nav row">
            <div 
              className={ "back button" + ( page === 0 ? ' disabled' : '') }
              onClick={ this.back }
            >
                BACK
            </div>
            { this.getPageIndicator() }
            <div 
              className={ "next button " + ( selectedLetter === null ? ' disabled' : '')} 
              onClick={  this.next }
            >
              {page === 4 ? 'START' : 'NEXT'}
            </div>
          </div>
        </div>

      </div>
    )
  }

}