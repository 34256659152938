import React from 'react';

import './App.scss';
import { MainController, ClientMap, PerformanceStages } from './types'
import { ClientConnectionManager } from './ClientConnectionManager'
import { CanvasController } from './CanvasController';
import { DemoCanvas } from './DemoCanvas'
import { MainCanvas } from './MainCanvas'
import { Instructions } from './Instructions'

import iconFacebook from './images/icon-fb.svg'
import iconInsta from './images/icon-insta.svg'
import iconTwitter from './images/icon-twitter.svg'
import iconWeb from './images/icon-web.svg'
import { alphabet } from './Util';

export interface MainControllerProps {
  canvas: HTMLCanvasElement
}

export interface AppState {
  instructionsRead: boolean,
  instructionsOpen: boolean,
  
  selectedInitial: number, // A = 0, B = 1, C = 2 etc
  hasConnected: boolean,
  connectionAttempted: boolean,
  performanceStage: number | null

}

class App extends React.Component<MainControllerProps, AppState> implements MainController  {
  
  clientId: number = -1
  updateTime: number = new Date().getTime()
  connectionManager: ClientConnectionManager
  canvasController: CanvasController | null = null
  clientMap: ClientMap = new Map()
  activationValues: Float32Array = new Float32Array()
  availableZones: Float32Array = new Float32Array([0, 0, 0, 0])

  constructor (props: MainControllerProps) {
    super(props)

    this.state = {
      instructionsRead: false,
      instructionsOpen: false,
  
      selectedInitial: Math.floor(Math.random() * 26),
      hasConnected: false,
      connectionAttempted: false,
      performanceStage: null
    }

    this.connectionManager = new ClientConnectionManager(this)
    this.connectionManager.connect()
  }

  render () {
    
    const showSplash = this.state.performanceStage === null || this.state.performanceStage === PerformanceStages.NO_PERFORMANCE
    const showCredits = this.state.performanceStage === PerformanceStages.PERFORMANCE_ENDED
    const showStart = this.state.instructionsRead === false && this.state.performanceStage !== PerformanceStages.NO_PERFORMANCE && this.state.hasConnected

    const instructionsClassName = this.state.instructionsOpen ? 'instructions' : 'instructions hidden'

    const followLink = (destination: string) => {
      let url
      switch(destination) {

        case 'instagram':
          url = 'https://www.instagram.com/unwireddancetheatre/'
          break
        case 'twitter':
          url = 'https://twitter.com/UnwiredDance'  
        break
        case 'website':
          url = 'https://www.unwireddancetheatre.com/'
          break
      }
      window.open(url)
    }

    if (!this.state.connectionAttempted) {
      return (
        <div className="splash-screen-container">
          <div className="splash-screen">Connecting...</div>
        </div>
      )
    }

    else if (showStart) {
      return (
        <div className="splash-screen-container">
          <DemoCanvas />
          <div className="splash-screen">
            <div className="title">STRINGS</div>
            <div className="go-away">
              Welcome!<br/><br />
              Please follow the next steps to get<br/>
              you ready for the performance.
            </div>
            <div 
              className="start-button"
              onClick={ () => { this.setState({instructionsRead: true, instructionsOpen: true})}}
            >
                START
              </div>
          </div>
        </div>
      )
    }
    
    else if (showSplash) {
      return (
        <div className="splash-screen-container">
          <DemoCanvas />
          <div className="splash-screen">
            <div className="title">STRINGS</div>
            <div className="go-away">
              <div>Strings is inactive at the moment</div>
              <div>Click <a target="blank" href="https://www.unwireddancetheatre.com/whats-on">here</a> for more info about the <br />next performances.</div>
            </div>
          </div>
        </div>
      )
    }

    else if (showCredits) {
      return (
        <div className="credits-container">
          <DemoCanvas />
          <div className="credits">
            
            <div className="header">
              <div className="title">STRINGS</div>

            </div>
            <div className="row">
              <div className="name">by Unwired Dance Theatre</div>
            </div>

            <div className="row">
              <div className="role">Direction, performance, creative<br />coding, electronics</div>
              <div className="name">Clemence Debaig</div>
            </div>
            <div className="row">
              <div className="role">UI, website, and network coding</div>
              <div className="name">Ed Boucher</div>
            </div>
            <div className="row">
              <div className="role">Music</div>
              <div className="name">Christina Karpodini</div>
            </div>
            <div className="row">
              <div className="role">Creative input and dance partners</div>
              <div className="name">Chloe Bellou, Kristia Morabito, <br />Marcello Licitra</div>
            </div>
            <div className="row">
              <div className="role">Special thanks for their support</div>
              <div className="name">Ugly Duck, Goldsmiths University, <br />Brendan Drake, and Kate Ladenheim</div>
            </div>
            <div className="row icons">
              <img src={iconInsta} alt="Instagram" onClick={ () => followLink('instagram') }/>
              
              <img src={iconTwitter} className="twitter" alt="Twitter" onClick={ () => followLink('twitter') }/>
              <img src={iconWeb} alt="Website" onClick={ () => followLink('website') }/>
            </div>
          
          </div>
        </div>
        
      )
    }

    return (
      <>
        <MainCanvas owner={this} />
        <Instructions 
          className={ instructionsClassName }
          onLetterSet={ this.onInititalSelected }
          onFinished={ this.onInstructionsClick }
        />
      </>
    );
  }

  onInititalSelected = (initial: number) => {
    this.setState({selectedInitial: initial})
  } 

  onInstructionsClick = () => {    
    this.setState({ instructionsOpen: false })
    setTimeout(() => {
      this.canvasController!.onResize()
    })
  }

  componentDidMount () {
    this.setupTimers()
  }

  componentWillUnmount () {}

  setupTimers () {
    // Check the connection every second
    setInterval(this.pollConnection, 1000)
    setInterval(this.sendUpdateToServer, 150)
    
    // Give the backend a chance to respond
    setTimeout(() => {
      this.setState({connectionAttempted: true})
    }, 500)
  }

  sendUpdateToServer = () => {
    if (!this.canvasController) return
    const { position, mouseDown, mouseInBounds } = this.canvasController
    const data = new Float32Array(8)

    const top = position.y < 0.5 ? true : false
    const left = position.x < 0.5 ? true : false
    
    data[0] = position.x
    data[1] = position.y
    data[2] = mouseDown && mouseInBounds ? 1 : 0
    
    // Will be more sophisticated with targets
    data[3] = top && left ? 1 : 0
    data[4] = top && !left ? 1 : 0
    data[5] = !top && left ? 1 : 0
    data[6] = !top && !left ? 1 : 0
    data[7] = this.state.selectedInitial

    this.connectionManager.sendMessage(data)
  }

  onZoneUpdate (data: Float32Array) {
    this.availableZones = data
  }

  onPerformanceStageUpdate (performanceStage: number) {
    
    this.setState({
      performanceStage
    })
  }

  onClientData (data: Float32Array) {
    this.updateTime = new Date().getTime()
    const { clientMap } = this

    this.activationValues = data.slice(0, 4)
    const activeList: number[] = []

    for (let i = 4; i < data.length; i += 5) {
      const id = data[i]
      const mouseDown = data[i + 1] === 0 ? false : true
      const x = data[i + 2]
      const y = data[i + 3]
      const initial = data[i + 4]

      const existing = this.clientMap.get(id)
      activeList.push(id)
      if (!existing) {
        this.clientMap.set(id, {
          target : { x, y },
          position: { x, y },
          velocity: { x: 0, y: 0 },
          mouseDown,
          previousPositions: [],
          previousClicks: [],
          initial: alphabet[initial]
        })
      }
      else {
        let maxLength = 3

        const _previous = existing.previousPositions.slice()
        _previous.unshift(existing.target)
        if (_previous.length > maxLength) _previous.pop()
        
        const _previousClicks = existing.previousClicks.slice()
        _previousClicks.unshift(existing.mouseDown)
        if (_previousClicks.length > maxLength) _previousClicks.pop()

        clientMap.set(id, {
          ...existing,
          mouseDown,
          previousPositions: _previous,
          previousClicks: _previousClicks,
          target: { x, y },
          initial: alphabet[initial]
        })
      }
    }

    clientMap.forEach((data, id) => {
      if(!activeList.includes(id)) clientMap.delete(id)
    })

    if (!this.canvasController) return

    this.canvasController.update(
      clientMap, 
      this.activationValues, 
      this.availableZones, 
      this.state.performanceStage!,
      new Date().getTime()
    )
  }

  onConnectionEstablished (id: number) {
    this.clientId = id
    console.log('connection established')
    this.setState({
      hasConnected: true,
    })
  }

  setCanvasController (c: CanvasController) {
    this.canvasController = c
  }

  onConnectionLost () {
    // Update the UI presumably
  }

  pollConnection = () => {
    this.connectionManager.checkConnection()
  }

  showError (e: Error | any) {
    // this.canvasController.error = e
  }

  clearError () {
    // this.canvasController.error = null
  }

}

export default App;
