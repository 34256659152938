import { MainController } from "./types"

const serverAddress = 'wss://strings.dance:8080'
// const serverAddress = 'ws://192.168.0.67:8080'

export class ClientConnectionManager {

  ws: WebSocket | null = null
  id: number | null = null
  connected: Boolean = false
  owner: MainController

  constructor(owner: MainController) {
    this.owner = owner
  }

  connect = (): void => {
    this.ws = new WebSocket(serverAddress, 'wss')
    this.resetEventListeners()
  }

  resetEventListeners (): void {
    this.ws!.onopen = this.onOpen
    this.ws!.onclose = this.onClose
    this.ws!.onmessage = this.onMessage
  }

  sendMessage (message: Float32Array) {
    if (this.connected && this.ws) {
      this.ws.send(message)
    }
  }

  onOpen = (e: Event): void => {
    console.log('connection open')
    this.connected = true
    this.ws!.send(`${Math.random() * Math.pow(10, 7)}`)
    
  }

  onClose = (e: Event): void => {
    this.connected = false
    this.checkConnection()
  }

  onMessage = async (e: MessageEvent) => {
    const { data } = e
   
    if (typeof data === 'string') {
      this.id = JSON.parse(data).id
      this.owner.onConnectionEstablished(this.id as number)
    }
    else {
      if (data.arrayBuffer) {
        const buffer = await data.arrayBuffer()
        const parsed = new Float32Array(buffer)
        this.routeMessage(parsed)
      }
      else {
        // iOS being pants      
        const f = new FileReader()
        f.onloadend = (e) => {
          const parsed = new Float32Array(e.target!.result as ArrayBuffer)
          this.routeMessage(parsed)
          
        }
        f.readAsArrayBuffer(data)
         
      }
    }
  }

  routeMessage (m: Float32Array): void {
    this.owner.onZoneUpdate(m.slice(0, 4))
    this.owner.onClientData(m.slice(4, m.length - 1))
    this.owner.onPerformanceStageUpdate(m[m.length - 1])
  }

  checkConnection (): void {
    if (this.ws?.readyState === WebSocket.CLOSED) {
      console.log('resetting connection')
      this.connected = false
      this.connect()
    }
  }

  

}