import React, { Component, createRef, RefObject } from 'react'

interface DemoCanvasState {
  isMounted: boolean
}

export class DemoCanvas extends Component<any, DemoCanvasState> {

  canvasRef: RefObject<HTMLCanvasElement>

  silhouetteSVG: SVGElement
  silhouette: HTMLImageElement | null = null


  constructor (props: any) {
    super(props)
    this.canvasRef = createRef<HTMLCanvasElement>()

    let l: Element = document.getElementById('silhouette')!
    this.silhouetteSVG = l as SVGElement
    let svgURL = new XMLSerializer().serializeToString(this.silhouetteSVG);
    this.silhouette = new Image()
    this.silhouette.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
  }

  componentDidMount () {
    this.setState({ isMounted: true })
    requestAnimationFrame(this.update)
  }

  componentWillUnmount () {
    this.setState({isMounted: false})
  }

  update = () => {
    if (this.state.isMounted) {
      const ratio = 0.6363
      
      if (this.canvasRef.current) {
        const canvas = this.canvasRef.current
        const ctx = canvas.getContext('2d')
        const rect = canvas.getBoundingClientRect()
        
        canvas.width = rect.width
        canvas.height = rect.height

        const height = rect.height * .8
        const width = height * ratio
        
        const x = (rect.width - width) / 2
        const y = (rect.height - height) / 2

        ctx!.drawImage(this.silhouette!, x, y, width, height)
      }
      
      requestAnimationFrame(this.update)
    }
  }

  render () {
    return <canvas className="demo-canvas" ref={this.canvasRef}></canvas>
  }

}