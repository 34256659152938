import React, { Component, createRef, RefObject } from 'react'
import { MainController } from './types'
import { CanvasController } from './CanvasController'

interface MainCanvasProps {
  owner: MainController
}

interface MainCanvasState {}

export class MainCanvas extends Component<MainCanvasProps, MainCanvasState> {

  canvasRef: RefObject<HTMLCanvasElement>
  canvasController: CanvasController | null = null

  constructor (props: any) {
    super(props)
    this.canvasRef = createRef<HTMLCanvasElement>()
  }

  componentDidMount () {
    this.setState({ isMounted: true })
    this.canvasController = new CanvasController(this.canvasRef!.current!, this.props.owner)
    this.props.owner.setCanvasController(this.canvasController)
  }

  componentWillUnmount () {
    this.setState({isMounted: false})
  }

  render () {
    return <canvas className="control-surface" ref={this.canvasRef}></canvas>
  }

}