import { Vector, RGBA, Rect } from './types'

const length = (v: Vector): number => {
  return Math.sqrt(v.x * v.x + v.y * v.y) 
}

const lengthSq = (v: Vector): number => {
  return v.x * v.x + v.y * v.y
}

const normalise = (v: Vector): Vector => {
  const len = length(v)
  if (len === 0) return { x: 0, y: 0 }
  return { x: v.x / len, y: v.y / len }
}

const subtract = (v1: Vector, v2: Vector): Vector => {
  return { x: v1.x - v2.x, y: v1.y - v2.y }
}

const add = (v1: Vector, v2: Vector): Vector => {
  return { x: v1.x + v2.x, y: v1.y + v2.y }
}

const scale = (v: Vector, l: number): Vector => {
  return { x: v.x * l, y: v.y * l}
}

const setLength = (v: Vector, l: number): Vector => {
  return scale( normalise(v), l)
} 

export const VUtil = { length, lengthSq, normalise, add, subtract, scale, setLength }

export const cubicOut = (t: number) => {
  var f = t - 1.0;
  return f * f * f + 1.0;
}

export const pointInRect = (x: number, y: number, r: Rect): boolean => {
  if (x > r.x 
    && x < r.x + r.width 
    && y > r.y 
    && y < r.y + r.height
  ) {
    return true
  }
  return false
}

export const vectorInRect = (v: Vector, r: Rect): boolean => {
  const { x, y } = v
  return pointInRect(x, y, r)
}

export const valueTween = (from: number, to: number, dt: number, tweenLength = 150) : number => {
  return cubicOut(dt / tweenLength) * (to - from) + from 
}

export const vectorTween = (from: Vector, to: Vector, dt: number, tweenLength = 150): Vector => {
  const t = cubicOut(dt / tweenLength)
  return {
    x: t * (to.x - from.x) + from.y,
    y: t * (to.y - from.y) + from.y
  }
}

export const colourTween = (from: RGBA, to: RGBA, dt: number, tweenLength = 150): RGBA => {
  const t = cubicOut(dt / tweenLength)
  return {
    r: from.r + (to.r - from.r) * t,
    g: from.g + (to.g - from.g) * t,
    b: from.b + (to.b - from.b) * t,
    a: from.a + (to.a - from.a) * t,
  }
} 

export const rgbaToString = (c:RGBA) : string => {
  return `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`
}

export const getHotspotOpacity = (mouseDown: boolean, isAvailable: boolean, isActive: boolean) => {
  if (!isAvailable) return 0
  else {
    if (mouseDown && isActive) return 1
    else if (mouseDown) return .45
    else if (isActive) return .6
    else return .25
  }  
}

export const alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
